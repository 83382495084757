import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"

const SuccessContainer = styled.div`
  margin: 20px;
  padding: 10px;
  text-align: center;
  font-size: 22px;
`

const ContactSuccess = () => (
  <Layout>
    <SuccessContainer>
      Your message has been sent to our corporate office. Thank you!
    </SuccessContainer>
  </Layout>
)

export default ContactSuccess
